import Vue from "vue";
import RequestUtil from "~/utils/request-util";
Vue.mixin({
  methods: {
    getImage: path => {
      return `${process.env.NUXT_ENV_IMAGE_URL}/api/v1/storage${path}`;
    },
    getStoryFontFamily: story => {
      if (story.fontFamily === 'OPEN_SANS') {
        return "Open Sans, sans-serif";
      }
      if (story.fontFamily === 'MONTSERRAT') {
        return "Montserrat, sans-serif";
      }
      if (story.fontFamily === 'ROBOTO') {
        return "Roboto, sans-serif";
      }
      if (story.fontFamily === 'RALEWAY') {
        return "Raleway, sans-serif";
      }
      if (story.fontFamily === 'DANCING_SCRIPT') {
        return "Dancing Script, cursive";
      }
      if (story.fontFamily === 'MERIENDA') {
        return "Merienda, cursive";
      }
      if (story.fontFamily === 'MERIENDA') {
        return "Livvic, sans-serif";
      }
      return "Livvic, sans-serif";
    },
    defaultStory: qty => {
      let stories = [];
      for (let index = 0; index < qty; index++) {
        let story = {
          id: 6609,
          slug: "story-default-" + index,
          name: "Loading ...",
          imageUrl: "/logo_white.png",
          image: "/logo_white.png",
          chapterNew: 0,
          default: true,
          createdAt: 1658992369731
        }
        stories.push(story)
      }
      return stories
    },
    formatPrice: value => {
      const val = (value / 1).toFixed(0).replace('.', ',')
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
    },
    formatDateTime: value => {
      const m = new Date(value);
      m.setUTCHours(m.getUTCHours() + 7); // Add 7 hours to adjust to UTC+7
      return m.getUTCFullYear() + "/" + (m.getUTCMonth() + 1 < 10 ? '0' + (m.getUTCMonth() + 1) : m.getUTCMonth() + 1) + "/" + (m.getUTCDate() < 10 ? '0' + m.getUTCDate() : m.getUTCDate()) + " " + (m.getUTCHours() < 10 ? '0' + m.getUTCHours() : m.getUTCHours()) + ":" + (m.getUTCMinutes() < 10 ? '0' + m.getUTCMinutes() : m.getUTCMinutes());
    },
    formatDateTime3: value => {
      if (value == null) {
        return null;
      }
      const m = new Date(value);
      m.setUTCHours(m.getUTCHours() + 7); // Add 7 hours to adjust to UTC+7
      return m.getUTCFullYear() + "/" + (m.getUTCMonth() + 1 < 10 ? '0' + (m.getUTCMonth() + 1) : m.getUTCMonth() + 1) + "/" + (m.getUTCDate() < 10 ? '0' + m.getUTCDate() : m.getUTCDate());
    },
    formatDateTime2: value => {
      let year = value[0];
      let month = value[1];
      let day = value[2];
      let hour = value[3];
      let min = value[4];
      let second = value[5];

      if (min < 10) {
        min = '0' + min;
      }
      if (second < 10) {
        second = '0' + second;
      }
      if (hour < 10) {
        hour = '0' + hour;
      }
      if (day < 10) {
        day = '0' + day;
      }
      if (month < 10) {
        month = '0' + month;
      }
      return year + "-" + month + "-" + day + " " + hour + ":" + min + ":" + second;
    },
    formatTime3(inputTime) {
      // Chuyển đổi thời gian nhập vào thành đối tượng Date
      const specifiedTime = typeof inputTime === 'number' ? new Date(inputTime) : new Date(inputTime[0], inputTime[1] - 1, inputTime[2], inputTime[3], inputTime[4], inputTime[5]);

      // Lấy thời gian hiện tại
      const currentTime = new Date();

      // Tính khoảng cách thời gian giữa thời gian hiện tại và thời gian được cung cấp
      const timeDifference = currentTime.getTime() - specifiedTime.getTime();

      // Chuyển đổi khoảng cách thời gian từ millisecond sang phút
      const timeDifferenceInMinutes = Math.round(timeDifference / (1000 * 60));

      // Xác định xem là bao nhiêu phút trước, giờ trước hoặc ngày trước
      if (timeDifferenceInMinutes < 60) {
        return timeDifferenceInMinutes + " phút trước";
      } else if (timeDifferenceInMinutes < 1440) {
        const hours = Math.floor(timeDifferenceInMinutes / 60);
        return hours + " giờ trước";
      } else {
        const days = Math.floor(timeDifferenceInMinutes / 1440);
        return days + " ngày trước";
      }
    },
    numberFormat(value) {
      if (value === null || value === undefined) {
        return 0;
      }
      return value.toLocaleString('en-US');
    },
    numberFormat2(number) {
      try {
        if (number >= 1000000000) {
          return (number / 1000000000).toFixed(2) + 'B';
        }
        if (number >= 1000000) {
          return (number / 1000000).toFixed(2) + 'M';
        }
        if (number >= 1000) {
          return (number / 1000).toFixed(1) + 'K';
        }
        return number.toString();
      } catch (e) {
        return 0;
      }

    },
    numberFormat3(number) {
      try {
        if (number >= 1000000000) {
          return (number / 1000000000).toFixed(2) + 'B';
        }
        if (number >= 1000000) {
          return (number / 1000000).toFixed(0) + 'M';
        }
        if (number >= 1000) {
          return (number / 1000).toFixed(0) + 'K';
        }
        return number.toString();
      } catch (e) {
        return 0;
      }

    },
    isValidPhoneNumber(phoneNumber) {
      const phoneNumberRegex = /(84|0[3|5|7|8|9])+([0-9]{8})\b/;
      return phoneNumberRegex.test(phoneNumber);
    },
    isValidEmail(email) {
      // Regular expression for validating an email
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

      return emailPattern.test(email);
    },
    acronymText(text) {
      // Tách chuỗi thành các từ
      const words = text.split(' ');

      // Nếu số từ ít hơn hoặc bằng 2, trả về chuỗi gốc
      if (words.length <= 3) {
        return text;
      }

      // Viết tắt tất cả các từ trừ hai từ cuối cùng
      let abbreviatedWords = words.slice(0, -2).map(word => word[0]).join('') + ' ' + words.slice(-2).join(' ');

      return abbreviatedWords;
    },
    notificationRedirectLink: notification => {
      if (notification.type === 'COMMENT_STORY') {
        let storySlug = notification.data === null ? "truyen-slug" : notification.data.slug;
        return "/"+storySlug+"/" + notification.objectId;
      }
      if (notification.type === 'STORY_USER') {
        let storySlug = notification.data === null ? "truyen-slug" : notification.data.slug;
        return "/"+storySlug+"/" + notification.objectId;
      }
      if (notification.type === 'COMMENT_CHAPTER') {
        let storySlug = notification.data === null ? "truyen-slug" : notification.data.nameIndex;
        return "/"+storySlug+"/chuong/" + notification.data.chapter + notification.objectId;
      }
      if (notification.type === 'ORDER') {
        return "/tai-khoan/chi-tiet-truyen/" + notification.objectId + "?tab=ORDER";
      }
      if (notification.type === 'STORY') {
        return "/tai-khoan/chi-tiet-truyen/" + notification.objectId;
      }
      if (notification.type === 'STORY_AWARD') {
        return "/tai-khoan/chi-tiet-truyen/" + notification.objectId+ "?tab=AWARD";
      }
      if (notification.type === 'WALLET') {
        return "/tai-khoan/vi-cua-toi";
      }
      if (notification.type === 'WALLET_REQUEST') {
        return "/tai-khoan/nap-rut";
      }
      if (notification.type === 'DONATE') {
        return "/tai-khoan/tac-gia/donate";
      }
      if (notification.type === 'AUTHOR') {
        return "/tai-khoan/tu-truyen-cua-toi";
      }
      if (notification.type === 'AUTHOR_USER') {
        let authorName = notification.data === null ? "tac-gia-slug" : notification.data.name;
        return "/tac-gia/" + authorName + "/" + notification.objectId;
      }
      if (notification.type === 'RANKING') {
        return "/tai-khoan/tac-gia/danh-tieng";
      }
      return "/tai-khoan/thong-bao";
    },
  }
})

