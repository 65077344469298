import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _0784d6f2 = () => interopDefault(import('../pages/404.vue' /* webpackChunkName: "pages/404" */))
const _8c99ceba = () => interopDefault(import('../pages/blogs/index.vue' /* webpackChunkName: "pages/blogs/index" */))
const _c4bc1c1c = () => interopDefault(import('../pages/cau-hoi-thuong-gap.vue' /* webpackChunkName: "pages/cau-hoi-thuong-gap" */))
const _01367e52 = () => interopDefault(import('../pages/dieu-khoan-chinh-sach.vue' /* webpackChunkName: "pages/dieu-khoan-chinh-sach" */))
const _bf1020b8 = () => interopDefault(import('../pages/su-kien/index.vue' /* webpackChunkName: "pages/su-kien/index" */))
const _7600a4c0 = () => interopDefault(import('../pages/tai-khoan/index.vue' /* webpackChunkName: "pages/tai-khoan/index" */))
const _e4f1e548 = () => interopDefault(import('../pages/tim-kiem.vue' /* webpackChunkName: "pages/tim-kiem" */))
const _3b54d277 = () => interopDefault(import('../pages/xac-thuc-tai-khoan.vue' /* webpackChunkName: "pages/xac-thuc-tai-khoan" */))
const _40da0cbe = () => interopDefault(import('../pages/su-kien/cuoc-thi-sang-tac.vue' /* webpackChunkName: "pages/su-kien/cuoc-thi-sang-tac" */))
const _386a665a = () => interopDefault(import('../pages/tai-khoan/binh-luan-cua-toi.vue' /* webpackChunkName: "pages/tai-khoan/binh-luan-cua-toi" */))
const _32484648 = () => interopDefault(import('../pages/tai-khoan/cai-dat-thanh-toan.vue' /* webpackChunkName: "pages/tai-khoan/cai-dat-thanh-toan" */))
const _86ef198c = () => interopDefault(import('../pages/tai-khoan/co-che-quy-doi.vue' /* webpackChunkName: "pages/tai-khoan/co-che-quy-doi" */))
const _6308e4bd = () => interopDefault(import('../pages/tai-khoan/cuoc-thi-sang-tac.vue' /* webpackChunkName: "pages/tai-khoan/cuoc-thi-sang-tac" */))
const _0fd5f020 = () => interopDefault(import('../pages/tai-khoan/dang-truyen.vue' /* webpackChunkName: "pages/tai-khoan/dang-truyen" */))
const _26f08236 = () => interopDefault(import('../pages/tai-khoan/dieu-khoan-chinh-sach.vue' /* webpackChunkName: "pages/tai-khoan/dieu-khoan-chinh-sach" */))
const _7462f759 = () => interopDefault(import('../pages/tai-khoan/doi-go.vue' /* webpackChunkName: "pages/tai-khoan/doi-go" */))
const _642760e8 = () => interopDefault(import('../pages/tai-khoan/hom-thu.vue' /* webpackChunkName: "pages/tai-khoan/hom-thu" */))
const _22bdbe62 = () => interopDefault(import('../pages/tai-khoan/nap-rut.vue' /* webpackChunkName: "pages/tai-khoan/nap-rut" */))
const _26bbe8c4 = () => interopDefault(import('../pages/tai-khoan/nhan-thuong/index.vue' /* webpackChunkName: "pages/tai-khoan/nhan-thuong/index" */))
const _77aca18e = () => interopDefault(import('../pages/tai-khoan/phuong-thuc-thanh-toan.vue' /* webpackChunkName: "pages/tai-khoan/phuong-thuc-thanh-toan" */))
const _1199d8a5 = () => interopDefault(import('../pages/tai-khoan/thong-bao.vue' /* webpackChunkName: "pages/tai-khoan/thong-bao" */))
const _95aa51fc = () => interopDefault(import('../pages/tai-khoan/tong-quan-tu-truyen.vue' /* webpackChunkName: "pages/tai-khoan/tong-quan-tu-truyen" */))
const _484d6b91 = () => interopDefault(import('../pages/tai-khoan/truyen-dang-doc.vue' /* webpackChunkName: "pages/tai-khoan/truyen-dang-doc" */))
const _a2a3c294 = () => interopDefault(import('../pages/tai-khoan/truyen-yeu-thich.vue' /* webpackChunkName: "pages/tai-khoan/truyen-yeu-thich" */))
const _3b845668 = () => interopDefault(import('../pages/tai-khoan/tu-truyen-cua-toi.vue' /* webpackChunkName: "pages/tai-khoan/tu-truyen-cua-toi" */))
const _7bb076d0 = () => interopDefault(import('../pages/tai-khoan/user-change-password.vue' /* webpackChunkName: "pages/tai-khoan/user-change-password" */))
const _5ac11298 = () => interopDefault(import('../pages/tai-khoan/vi-cua-toi.vue' /* webpackChunkName: "pages/tai-khoan/vi-cua-toi" */))
const _8abd0bfe = () => interopDefault(import('../pages/tai-khoan/su-kien/top-tac-gia.vue' /* webpackChunkName: "pages/tai-khoan/su-kien/top-tac-gia" */))
const _08ebeb7c = () => interopDefault(import('../pages/tai-khoan/tac-gia/danh-sach-theo-doi.vue' /* webpackChunkName: "pages/tai-khoan/tac-gia/danh-sach-theo-doi" */))
const _1facda5c = () => interopDefault(import('../pages/tai-khoan/tac-gia/danh-tieng.vue' /* webpackChunkName: "pages/tai-khoan/tac-gia/danh-tieng" */))
const _66158150 = () => interopDefault(import('../pages/tai-khoan/tac-gia/donate.vue' /* webpackChunkName: "pages/tai-khoan/tac-gia/donate" */))
const _06e04561 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _7bd2c978 = () => interopDefault(import('../pages/tai-khoan/chi-tiet-truyen/_id.vue' /* webpackChunkName: "pages/tai-khoan/chi-tiet-truyen/_id" */))
const _645b22c6 = () => interopDefault(import('../pages/tai-khoan/sua-truyen/_id.vue' /* webpackChunkName: "pages/tai-khoan/sua-truyen/_id" */))
const _2129446f = () => interopDefault(import('../pages/tai-khoan/viet-truyen/_id.vue' /* webpackChunkName: "pages/tai-khoan/viet-truyen/_id" */))
const _da934e56 = () => interopDefault(import('../pages/tai-khoan/sua-chuong/_id/_chapterId.vue' /* webpackChunkName: "pages/tai-khoan/sua-chuong/_id/_chapterId" */))
const _7a392a0e = () => interopDefault(import('../pages/blogs/_id/index.vue' /* webpackChunkName: "pages/blogs/_id/index" */))
const _06989fdf = () => interopDefault(import('../pages/su-kien/_slug/index.vue' /* webpackChunkName: "pages/su-kien/_slug/index" */))
const _6efbb0e4 = () => interopDefault(import('../pages/the-loai/_category_slug.vue' /* webpackChunkName: "pages/the-loai/_category_slug" */))
const _489a757d = () => interopDefault(import('../pages/tac-gia/_slug/_id.vue' /* webpackChunkName: "pages/tac-gia/_slug/_id" */))
const _c22a7e72 = () => interopDefault(import('../pages/_slug/_id/index.vue' /* webpackChunkName: "pages/_slug/_id/index" */))
const _60acacf5 = () => interopDefault(import('../pages/_slug/_id/chuong/_chapterId.vue' /* webpackChunkName: "pages/_slug/_id/chuong/_chapterId" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/404",
    component: _0784d6f2,
    name: "404"
  }, {
    path: "/blogs",
    component: _8c99ceba,
    name: "blogs"
  }, {
    path: "/cau-hoi-thuong-gap",
    component: _c4bc1c1c,
    name: "cau-hoi-thuong-gap"
  }, {
    path: "/dieu-khoan-chinh-sach",
    component: _01367e52,
    name: "dieu-khoan-chinh-sach"
  }, {
    path: "/su-kien",
    component: _bf1020b8,
    name: "su-kien"
  }, {
    path: "/tai-khoan",
    component: _7600a4c0,
    name: "tai-khoan"
  }, {
    path: "/tim-kiem",
    component: _e4f1e548,
    name: "tim-kiem"
  }, {
    path: "/xac-thuc-tai-khoan",
    component: _3b54d277,
    name: "xac-thuc-tai-khoan"
  }, {
    path: "/su-kien/cuoc-thi-sang-tac",
    component: _40da0cbe,
    name: "su-kien-cuoc-thi-sang-tac"
  }, {
    path: "/tai-khoan/binh-luan-cua-toi",
    component: _386a665a,
    name: "tai-khoan-binh-luan-cua-toi"
  }, {
    path: "/tai-khoan/cai-dat-thanh-toan",
    component: _32484648,
    name: "tai-khoan-cai-dat-thanh-toan"
  }, {
    path: "/tai-khoan/co-che-quy-doi",
    component: _86ef198c,
    name: "tai-khoan-co-che-quy-doi"
  }, {
    path: "/tai-khoan/cuoc-thi-sang-tac",
    component: _6308e4bd,
    name: "tai-khoan-cuoc-thi-sang-tac"
  }, {
    path: "/tai-khoan/dang-truyen",
    component: _0fd5f020,
    name: "tai-khoan-dang-truyen"
  }, {
    path: "/tai-khoan/dieu-khoan-chinh-sach",
    component: _26f08236,
    name: "tai-khoan-dieu-khoan-chinh-sach"
  }, {
    path: "/tai-khoan/doi-go",
    component: _7462f759,
    name: "tai-khoan-doi-go"
  }, {
    path: "/tai-khoan/hom-thu",
    component: _642760e8,
    name: "tai-khoan-hom-thu"
  }, {
    path: "/tai-khoan/nap-rut",
    component: _22bdbe62,
    name: "tai-khoan-nap-rut"
  }, {
    path: "/tai-khoan/nhan-thuong",
    component: _26bbe8c4,
    name: "tai-khoan-nhan-thuong"
  }, {
    path: "/tai-khoan/phuong-thuc-thanh-toan",
    component: _77aca18e,
    name: "tai-khoan-phuong-thuc-thanh-toan"
  }, {
    path: "/tai-khoan/thong-bao",
    component: _1199d8a5,
    name: "tai-khoan-thong-bao"
  }, {
    path: "/tai-khoan/tong-quan-tu-truyen",
    component: _95aa51fc,
    name: "tai-khoan-tong-quan-tu-truyen"
  }, {
    path: "/tai-khoan/truyen-dang-doc",
    component: _484d6b91,
    name: "tai-khoan-truyen-dang-doc"
  }, {
    path: "/tai-khoan/truyen-yeu-thich",
    component: _a2a3c294,
    name: "tai-khoan-truyen-yeu-thich"
  }, {
    path: "/tai-khoan/tu-truyen-cua-toi",
    component: _3b845668,
    name: "tai-khoan-tu-truyen-cua-toi"
  }, {
    path: "/tai-khoan/user-change-password",
    component: _7bb076d0,
    name: "tai-khoan-user-change-password"
  }, {
    path: "/tai-khoan/vi-cua-toi",
    component: _5ac11298,
    name: "tai-khoan-vi-cua-toi"
  }, {
    path: "/tai-khoan/su-kien/top-tac-gia",
    component: _8abd0bfe,
    name: "tai-khoan-su-kien-top-tac-gia"
  }, {
    path: "/tai-khoan/tac-gia/danh-sach-theo-doi",
    component: _08ebeb7c,
    name: "tai-khoan-tac-gia-danh-sach-theo-doi"
  }, {
    path: "/tai-khoan/tac-gia/danh-tieng",
    component: _1facda5c,
    name: "tai-khoan-tac-gia-danh-tieng"
  }, {
    path: "/tai-khoan/tac-gia/donate",
    component: _66158150,
    name: "tai-khoan-tac-gia-donate"
  }, {
    path: "/",
    component: _06e04561,
    name: "index"
  }, {
    path: "/tai-khoan/chi-tiet-truyen/:id",
    component: _7bd2c978,
    name: "tai-khoan-chi-tiet-truyen-id"
  }, {
    path: "/tai-khoan/sua-truyen/:id",
    component: _645b22c6,
    name: "tai-khoan-sua-truyen-id"
  }, {
    path: "/tai-khoan/viet-truyen/:id",
    component: _2129446f,
    name: "tai-khoan-viet-truyen-id"
  }, {
    path: "/tai-khoan/sua-chuong/:id/:chapterId?",
    component: _da934e56,
    name: "tai-khoan-sua-chuong-id-chapterId"
  }, {
    path: "/blogs/:id",
    component: _7a392a0e,
    name: "blogs-id"
  }, {
    path: "/su-kien/:slug",
    component: _06989fdf,
    name: "su-kien-slug"
  }, {
    path: "/the-loai/:category_slug?",
    component: _6efbb0e4,
    name: "the-loai-category_slug"
  }, {
    path: "/tac-gia/:slug?/:id?",
    component: _489a757d,
    name: "tac-gia-slug-id"
  }, {
    path: "/:slug/:id",
    component: _c22a7e72,
    name: "slug-id"
  }, {
    path: "/:slug/:id?/chuong/:chapterId?",
    component: _60acacf5,
    name: "slug-id-chuong-chapterId"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
