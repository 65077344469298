export const EventDetail = () => import('../../components/EventDetail.vue' /* webpackChunkName: "components/event-detail" */).then(c => wrapFunctional(c.default || c))
export const EventItem = () => import('../../components/EventItem.vue' /* webpackChunkName: "components/event-item" */).then(c => wrapFunctional(c.default || c))
export const EventList = () => import('../../components/EventList.vue' /* webpackChunkName: "components/event-list" */).then(c => wrapFunctional(c.default || c))
export const EventPointUser = () => import('../../components/EventPointUser.vue' /* webpackChunkName: "components/event-point-user" */).then(c => wrapFunctional(c.default || c))
export const GoogleAdsense = () => import('../../components/GoogleAdsense.vue' /* webpackChunkName: "components/google-adsense" */).then(c => wrapFunctional(c.default || c))
export const Home = () => import('../../components/Home.vue' /* webpackChunkName: "components/home" */).then(c => wrapFunctional(c.default || c))
export const PolicyAuthor = () => import('../../components/PolicyAuthor.vue' /* webpackChunkName: "components/policy-author" */).then(c => wrapFunctional(c.default || c))
export const Transaction = () => import('../../components/Transaction.vue' /* webpackChunkName: "components/transaction" */).then(c => wrapFunctional(c.default || c))
export const WalletHistory = () => import('../../components/WalletHistory.vue' /* webpackChunkName: "components/wallet-history" */).then(c => wrapFunctional(c.default || c))
export const Author = () => import('../../components/author/Author.vue' /* webpackChunkName: "components/author" */).then(c => wrapFunctional(c.default || c))
export const AuthorItem = () => import('../../components/author/AuthorItem.vue' /* webpackChunkName: "components/author-item" */).then(c => wrapFunctional(c.default || c))
export const AuthorName = () => import('../../components/author/AuthorName.vue' /* webpackChunkName: "components/author-name" */).then(c => wrapFunctional(c.default || c))
export const AuthorRank = () => import('../../components/author/AuthorRank.vue' /* webpackChunkName: "components/author-rank" */).then(c => wrapFunctional(c.default || c))
export const AuthorSearch = () => import('../../components/author/AuthorSearch.vue' /* webpackChunkName: "components/author-search" */).then(c => wrapFunctional(c.default || c))
export const AuthorTopItem = () => import('../../components/author/AuthorTopItem.vue' /* webpackChunkName: "components/author-top-item" */).then(c => wrapFunctional(c.default || c))
export const StoryCommentStory = () => import('../../components/story/CommentStory.vue' /* webpackChunkName: "components/story-comment-story" */).then(c => wrapFunctional(c.default || c))
export const StoryFormChapter = () => import('../../components/story/FormChapter.vue' /* webpackChunkName: "components/story-form-chapter" */).then(c => wrapFunctional(c.default || c))
export const StoryFormStory = () => import('../../components/story/FormStory.vue' /* webpackChunkName: "components/story-form-story" */).then(c => wrapFunctional(c.default || c))
export const Story1 = () => import('../../components/story/Story1.vue' /* webpackChunkName: "components/story1" */).then(c => wrapFunctional(c.default || c))
export const Story10 = () => import('../../components/story/Story10.vue' /* webpackChunkName: "components/story10" */).then(c => wrapFunctional(c.default || c))
export const Story11 = () => import('../../components/story/Story11.vue' /* webpackChunkName: "components/story11" */).then(c => wrapFunctional(c.default || c))
export const Story12 = () => import('../../components/story/Story12.vue' /* webpackChunkName: "components/story12" */).then(c => wrapFunctional(c.default || c))
export const Story14 = () => import('../../components/story/Story14.vue' /* webpackChunkName: "components/story14" */).then(c => wrapFunctional(c.default || c))
export const Story2 = () => import('../../components/story/Story2.vue' /* webpackChunkName: "components/story2" */).then(c => wrapFunctional(c.default || c))
export const Story3 = () => import('../../components/story/Story3.vue' /* webpackChunkName: "components/story3" */).then(c => wrapFunctional(c.default || c))
export const Story4 = () => import('../../components/story/Story4.vue' /* webpackChunkName: "components/story4" */).then(c => wrapFunctional(c.default || c))
export const Story5 = () => import('../../components/story/Story5.vue' /* webpackChunkName: "components/story5" */).then(c => wrapFunctional(c.default || c))
export const Story6 = () => import('../../components/story/Story6.vue' /* webpackChunkName: "components/story6" */).then(c => wrapFunctional(c.default || c))
export const Story7 = () => import('../../components/story/Story7.vue' /* webpackChunkName: "components/story7" */).then(c => wrapFunctional(c.default || c))
export const Story8 = () => import('../../components/story/Story8.vue' /* webpackChunkName: "components/story8" */).then(c => wrapFunctional(c.default || c))
export const Story9 = () => import('../../components/story/Story9.vue' /* webpackChunkName: "components/story9" */).then(c => wrapFunctional(c.default || c))
export const StoryBannerEvent = () => import('../../components/story/StoryBannerEvent.vue' /* webpackChunkName: "components/story-banner-event" */).then(c => wrapFunctional(c.default || c))
export const StoryDetailActionTab = () => import('../../components/story/StoryDetailActionTab.vue' /* webpackChunkName: "components/story-detail-action-tab" */).then(c => wrapFunctional(c.default || c))
export const StorySearch = () => import('../../components/story/StorySearch.vue' /* webpackChunkName: "components/story-search" */).then(c => wrapFunctional(c.default || c))
export const StoryStateComponent = () => import('../../components/story/StoryStateComponent.vue' /* webpackChunkName: "components/story-state-component" */).then(c => wrapFunctional(c.default || c))
export const StoryStateComponent2 = () => import('../../components/story/StoryStateComponent2.vue' /* webpackChunkName: "components/story-state-component2" */).then(c => wrapFunctional(c.default || c))
export const StorySuggest = () => import('../../components/story/StorySuggest.vue' /* webpackChunkName: "components/story-suggest" */).then(c => wrapFunctional(c.default || c))
export const CommonsCategory = () => import('../../components/commons/Category.vue' /* webpackChunkName: "components/commons-category" */).then(c => wrapFunctional(c.default || c))
export const CommonsCategoryMobile = () => import('../../components/commons/CategoryMobile.vue' /* webpackChunkName: "components/commons-category-mobile" */).then(c => wrapFunctional(c.default || c))
export const CommonsChapterNew = () => import('../../components/commons/ChapterNew.vue' /* webpackChunkName: "components/commons-chapter-new" */).then(c => wrapFunctional(c.default || c))
export const CommonsChartAuthor = () => import('../../components/commons/ChartAuthor.vue' /* webpackChunkName: "components/commons-chart-author" */).then(c => wrapFunctional(c.default || c))
export const CommonsCircleCheck = () => import('../../components/commons/CircleCheck.vue' /* webpackChunkName: "components/commons-circle-check" */).then(c => wrapFunctional(c.default || c))
export const CommonsCkeditorNuxt = () => import('../../components/commons/CkeditorNuxt.vue' /* webpackChunkName: "components/commons-ckeditor-nuxt" */).then(c => wrapFunctional(c.default || c))
export const CommonsEventPolicyWriting = () => import('../../components/commons/EventPolicyWriting.vue' /* webpackChunkName: "components/commons-event-policy-writing" */).then(c => wrapFunctional(c.default || c))
export const CommonsFooter = () => import('../../components/commons/Footer.vue' /* webpackChunkName: "components/commons-footer" */).then(c => wrapFunctional(c.default || c))
export const CommonsHeader = () => import('../../components/commons/Header.vue' /* webpackChunkName: "components/commons-header" */).then(c => wrapFunctional(c.default || c))
export const CommonsHistoryStory = () => import('../../components/commons/HistoryStory.vue' /* webpackChunkName: "components/commons-history-story" */).then(c => wrapFunctional(c.default || c))
export const CommonsLabelPrimary = () => import('../../components/commons/LabelPrimary.vue' /* webpackChunkName: "components/commons-label-primary" */).then(c => wrapFunctional(c.default || c))
export const CommonsLabelPrimary2 = () => import('../../components/commons/LabelPrimary2.vue' /* webpackChunkName: "components/commons-label-primary2" */).then(c => wrapFunctional(c.default || c))
export const CommonsLoading = () => import('../../components/commons/Loading.vue' /* webpackChunkName: "components/commons-loading" */).then(c => wrapFunctional(c.default || c))
export const CommonsLoading2 = () => import('../../components/commons/Loading2.vue' /* webpackChunkName: "components/commons-loading2" */).then(c => wrapFunctional(c.default || c))
export const CommonsModalLogin = () => import('../../components/commons/ModalLogin.vue' /* webpackChunkName: "components/commons-modal-login" */).then(c => wrapFunctional(c.default || c))
export const CommonsModalRecharge = () => import('../../components/commons/ModalRecharge.vue' /* webpackChunkName: "components/commons-modal-recharge" */).then(c => wrapFunctional(c.default || c))
export const CommonsModalRegister = () => import('../../components/commons/ModalRegister.vue' /* webpackChunkName: "components/commons-modal-register" */).then(c => wrapFunctional(c.default || c))
export const CommonsModalWithdraw = () => import('../../components/commons/ModalWithdraw.vue' /* webpackChunkName: "components/commons-modal-withdraw" */).then(c => wrapFunctional(c.default || c))
export const CommonsNotFoundContent = () => import('../../components/commons/NotFoundContent.vue' /* webpackChunkName: "components/commons-not-found-content" */).then(c => wrapFunctional(c.default || c))
export const CommonsNotification = () => import('../../components/commons/Notification.vue' /* webpackChunkName: "components/commons-notification" */).then(c => wrapFunctional(c.default || c))
export const CommonsPagination = () => import('../../components/commons/Pagination.vue' /* webpackChunkName: "components/commons-pagination" */).then(c => wrapFunctional(c.default || c))
export const CommonsWidgets = () => import('../../components/commons/Widgets.vue' /* webpackChunkName: "components/commons-widgets" */).then(c => wrapFunctional(c.default || c))
export const UserMenu = () => import('../../components/user/Menu.vue' /* webpackChunkName: "components/user-menu" */).then(c => wrapFunctional(c.default || c))
export const UserName = () => import('../../components/user/UserName.vue' /* webpackChunkName: "components/user-name" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
