import { render, staticRenderFns } from "./default.vue?vue&type=template&id=1b8f9ffb"
import script from "./default.vue?vue&type=script&async=true&defer=true&lang=js"
export * from "./default.vue?vue&type=script&async=true&defer=true&lang=js"
import style1 from "./default.vue?vue&type=style&index=1&id=1b8f9ffb&prod&lang=css"
import style2 from "./default.vue?vue&type=style&index=2&id=1b8f9ffb&prod&lang=scss"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {GoogleAdsense: require('/var/www/dockerize-nuxt/nuxt-app/components/GoogleAdsense.vue').default})
